

















import { Vue, Component, Prop } from 'vue-property-decorator'
import store, { AlbumSortMethod } from '@/store'
import router from '@/router'

@Component({})
export default class Nav extends Vue {
  showAboutScreen () {
    this.$router.push({
      name: 'About'
    })
  }

  showMusicScreen () {
    this.$router.push({
      name: 'Music'
    })
  }

  toggleAudioPlayback () {
    const currentUri = store.state.currentPlayerURI
    const uriToPlay = currentUri || store.getters.albumsAndPlaylistsInView[0]?.uri
    if (!uriToPlay) return
    store.dispatch.togglePlayState(uriToPlay)
  }

  toggleShuffle () {
    if (store.state.albumSortMethod === AlbumSortMethod.Random) {
      store.dispatch.changeAlbumSorting(AlbumSortMethod.ArtistName)
    } else {
      store.dispatch.changeAlbumSorting(AlbumSortMethod.Random)
    }
  }

  get currentRouteName () {
    return this.$route.name
  }

  get playerPaused () {
    return store.state.playerPaused
  }

  get shuffled () {
    return store.state.albumSortMethod === AlbumSortMethod.Random
  }
}
