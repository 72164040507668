




















import { SanitizedMedia } from '@/types/sanitizedMedia'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class Spines extends Vue {
  @Prop({ required: true }) media!: SanitizedMedia[]
  @Prop({ required: true }) textBaseline!: 'left' | 'right'

  selectSpine (media: SanitizedMedia) {
    this.$emit('selectSpine', media.id)
  }

  spineArtistName (mediaObj: SanitizedMedia) {
    if (mediaObj.type === 'playlist') return ''
    else return mediaObj.artists[0].name
  }
}
