

















import { Vue, Component, Prop } from 'vue-property-decorator'
import store from '@/store'
import hotkeys from 'hotkeys-js'

@Component({})
export default class RecordCover extends Vue {
  @Prop({ required: true }) album?: SpotifyApi.AlbumObjectFull

  mounted () {
    this.initializeHotKeys()
  }

  get recordCoverImgSrc () {
    return this.album?.images[0].url
  }

  get currentlyPlayingThisAlbum () {
    return !store.state.playerPaused && store.state.currentPlayerURI === this.album?.uri
  }

  togglePlayback () {
    const uri = this.album?.uri
    if (!uri) return
    store.dispatch.togglePlayState(uri)
  }

  initializeHotKeys () {
    hotkeys('space', (event, handler) => {
      event.preventDefault()
      const uri = this.album?.uri
      if (!uri) return
      store.dispatch.togglePlayState(uri)
    })
  }
}
