import { render, staticRenderFns } from "./RecordCover.vue?vue&type=template&id=0e38e602&scoped=true&"
import script from "./RecordCover.vue?vue&type=script&lang=ts&"
export * from "./RecordCover.vue?vue&type=script&lang=ts&"
import style0 from "./RecordCover.vue?vue&type=style&index=0&id=0e38e602&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e38e602",
  null
  
)

export default component.exports