







import { Vue, Component } from 'vue-property-decorator'
import Nav from '@/components/Nav.vue'
import store from '@/store'

@Component({
  components: {
    Nav
  }
})
export default class App extends Vue {
  mounted () {
    store.dispatch.getMusic()
  }
}
